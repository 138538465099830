// console.log('Hello')

import AOS from 'aos';
import 'aos/dist/aos.css'; 

AOS.init({
    disable: 'mobile',
    offset: 200,
    duration: 400,
    easing: 'ease-in-sine',
    delay: 100,
});